<div class="section">
    @defer (when loadDeferredView()) {
    <div class="titleContainer">
        <div class="subText">
            <p class="labelWrapper">
                <span class="label">
                    {{message()}}
                </span>
            </p>
        </div>
    </div>
    @if(showBackButton()) {
    <div class="actions">
        <button mat-button class="mainButton" (click)="onBackClicked()">Back</button>
    </div>
    }
    } @placeholder {
    <mat-spinner></mat-spinner>
    }
</div>