import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, signal } from '@angular/core';
import { ActionsService } from '../services/actions.service';
import { HouseholdService } from '../services/household.service';
import { JourneyLogsService } from '../services/journey-logs.service';
import { Location } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'save-confirmation',
  standalone: true,
  imports: [MatProgressSpinner],
  templateUrl: './save-confirmation.component.html',
  styleUrl: './save-confirmation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveConfirmationComponent implements OnInit {
  constructor(
    private actionService: ActionsService,
    private location: Location,
    private householdService: HouseholdService,
    private journeyLogsService: JourneyLogsService
  ) { }

  loadDeferredView = signal(false);
  showBackButton = signal(false);
  message = signal("Your data has been saved! You can now click logout button.");

  @Output() readonly showHeaderAndNavigation = new EventEmitter<boolean>();
  @Output() readonly showLogoutButton = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.showHeaderAndNavigation.emit(false);    

    const handler = async () => {
      await this.actionService.saveDataToAOS()
        .then(async () => {
          this.householdService.household = null;
          this.loadDeferredView.set(true);
          await this.journeyLogsService.createSaveAndExitLog("Successful");
          this.showLogoutButton.emit(true);
        })
        .catch(async (error: Error) => {
          this.message.set(`An error occurred while saving your data. Please press back and try again: ${error.message}`);
          this.loadDeferredView.set(true);
          this.showBackButton.set(true);
          await this.journeyLogsService.createSaveAndExitLog(`Unsuccessful - ${error.message}`);
          this.showLogoutButton.emit(true);
        });
    };

    void handler();
  }

  onBackClicked() {
    this.showHeaderAndNavigation.emit(true);
    this.showLogoutButton.emit(false);
    this.location.back();
  }
}
